/**
 * https://github.com/tc39/proposal-promise-with-resolvers/blob/main/polyfills.js
 *
 */

Promise.withResolvers ??= function withResolvers<T>() {
  if (!this) throw new TypeError('Promise.withResolvers called on non-object')
  const out = {} as {
    promise: Promise<T>
    resolve: (value: T | PromiseLike<T>) => void
    reject: (reason?: unknown) => unknown
  }
  out.promise = new this<T>((resolve, reject) => {
    out.resolve = resolve
    out.reject = reject
  })
  return out
}
